<template>
  <div class="404">
    <!-- pc -->
    <div class="notFound" v-if="!_isMobile()">
      <div class="contain">
        <img src="@/assets/404.png" />
      </div>
      <p>你好像来到了没有知识的沙漠～</p>
    </div>
    <div class="footer" v-if="!_isMobile()">
      <span class="word3">
        <a
          target="_blank"
          href="https://www.lumibayedu.com/privacy_policy"
          class="word2"
          >隐私协议</a
        >
        &nbsp;|&nbsp;
        <a
          target="_blank"
          href="https://www.lumibayedu.com/agreement"
          class="word2"
          >用户协议</a
        >
        &nbsp;|&nbsp;
        <a
          target="_blank"
          class="RecordNo"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001604"
          >湘公网安备 43019002001604号
        </a>
        |&nbsp;<a
          class="subtitle"
          target="_blank"
          href="https://beian.miit.gov.cn"
          >湘ICP备 18024560号 -1</a
        >
      </span>
    </div>
    <!-- 移动端 -->
    <div class="notFound" v-if="_isMobile()" style="margin: 120px auto">
      <div class="contain">
        <img src="@/assets/404.png" style="width: 266px; height: 199px" />
      </div>
      <p style="font-size: 16px">你好像来到了没有知识的沙漠～</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '404'
}
</script>

<style scoped lang="less">
.notFound {
  margin: 80px auto;
  img {
    width: 400px;
    height: 300px;
    margin-bottom: 25px;
  }
  p {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 43px;
  line-height: 43px;
  text-align: center;
  background: #ebebeb;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  color: #505050;
  a {
    text-decoration: none;
    color: #505050;
  }
}
</style>
